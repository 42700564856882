body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    height: 100%;
    @media (max-width: 990px) {
        background-color: #f4f1f0;
    }
    background-color: #f4f1f0;
    background-position-x: right;
    background-repeat: no-repeat;
    background-image: url(../img/background.jpg);
    background-size: contain;
}

@mixin small-phone-min {
    @media (min-width: 375px) {
        @content;
    }
}

@mixin phone-min {
    @media (min-width: 480px) {
        @content;
    }
}

@mixin big-phone-min {
    @media (min-width: 640px) {
        @content;
    }
}

@mixin tablet-min {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin large-tablet-min {
    @media (min-width: 991px) {
        @content;
    }
}

@mixin desktop-min {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin big-desktop-min {
    @media (min-width: 1366px) {
        @content;
    }
}
@media (min-width: 991px) {  
    .heightMax{
        height: 100vh!important;
    }
}
.cardStyle{
    margin: auto;
    width: 500px;
    iframe{
        height: 30vh;
    }
    .st-btn {
        width: 20% !important;
    }
    @include small-phone-min{
        margin: auto;
        width: 100%;
    }
    @include phone-min{
        margin: auto;
        width: 100%;
        iframe{
            height: 35vh;
        }
    }
    @include big-phone-min{
        margin: auto;
        width: 100%;
        iframe{
            height: 30vh;
        }
    }
    @include tablet-min{
        margin: auto;
        width: 100%;
        iframe{
            height: 35vh;
        }
    }
    @include large-tablet-min{
        margin: auto;
        width: 100%;
        background: rgba(255,255,255,0.85);
        border-radius: 10px;
        iframe{
            height: 28vh;
        }
    }
    @include desktop-min{
        margin: auto;
        width: 85%;
    }
    @media (min-width: 1200px){
        margin: auto;
        width: 500px;
        background: rgba(255,255,255,0.85);
        border-radius: 10px;
        iframe{
            height: 35vh;
        }
    }
    @media (min-width: 991px) {  
        iframe {
            height: 36vh;
        }
    }
}
.white{
    height: 100vh!important;
    margin: 0;
    padding: 0;
    
   @media (max-width: 992px) {
        background-color: #FFFFFF;
        height: 75vh!important;
    }
}
.zindex{
    z-index: 100;
}
.zindex-min{
    z-index: -100;
}
.logoHeader{
    font-size: 1.2rem;
    font-weight: bold;
    color: #FFFFFF;
    margin: auto;
    text-align: left;
}
.title-header{
    color: black;
}
.subtext-header{
    font-size: 12px;
    margin-bottom: 0;
}
.description{
    font-size: 1.5rem;
    text-align: left;
    font-weight: 400;
    color: #FFFFFF;
    background-color: black;
    padding: 0 30px 30px 30px;
    margin: auto;
    @media (min-width: 992px) {
        font-size: 2.5rem;
        line-height: normal;
    }
}
.shareBorder{
    border-bottom: #ECECEC 2px solid;
}
.linkComponent{
    font-size: 1rem;
    text-align: left;
    font-weight: bold;
    color: #000000;
    align-items: center;
    background-color: #FFFFFF;
    height: 42px;
    border-bottom: #ECECEC 2px solid;
    .link, a, a:hover{
        color: #000000;
        text-decoration: none;
    }
    @media (max-width: 767px) {
        font-size: 0.7rem;
    }
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:  wrap;
    align-items: center;
}

.button-icon {
    //padding-top: .3rem;
    width: 4rem;
}
.button-icon-social {
    width: 5rem;
    margin-left: 5px;
    margin-right: 5px;
}
.social-networks {
    text-align: center;
}
.button-go{
    text-align: center;
    background-color: #F3F4F6;
    color: #000;
    border-radius: 20px;
    margin-right: 5px;
    width: 80px;
    height: 30px;
    padding-top: 4px;
    font-size: 14px;
    @media (max-width: 767px) {
        width: 70px;
        height: 24px;
        padding-top: 3px;
        font-size: 12px;
    }
}
.button-title {
    flex-grow: 4;
}
.video-responsive {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    background-color: #000000;
}
.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.containerbp{
    position: relative;
}

.playButton{
    color: #000000;
    font-size: 5rem;
    font-weight: bold;
    position: absolute;
    margin-left: calc(50% - 40px);
    top: 20%;
    z-index: 100;
    border-radius: 50%;
    width: 5rem;
    padding: 0;
    background-image: radial-gradient(circle at center, white, rgba(255, 255, 255, 1));
    @media (max-width: 300px){
        font-size: 1.5rem;
        width: 2.5rem;
    }
    @media (max-width: 992px) {
        top: 40%;
        margin-left: calc(50% - 30px);
    }
    @media screen and (min-width: 301px) and (max-width: 359px){
         font-size: 2.5rem;width: 2.5rem;
         margin-left: calc(52% - 30px);
    }
    @media screen and (min-width: 360px) and (max-width: 450px){
        font-size: 2.5rem;width: 2.5rem;
        margin-left: calc(52% - 30px);
    }
    @media screen and (min-width: 450px) and (max-width: 550px) {
        font-size: 2.5rem;width: 2.5rem;
        margin-left: calc(52% - 30px);
    }
    @media screen and (min-width: 550px) and (max-width: 650px) {
        font-size: 2.5rem;width: 2.5rem;
        margin-left: calc(52% - 30px);
    }
    @media screen and (min-width: 651px) and (max-width: 992px) {
        font-size: 3.5rem;width: 3.5rem;
        margin-left: calc(52% - 30px);
    }
}  
.vimeo-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
 }
 .vimeo-wrapper iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 }
 .styles_closeButton__20ID4 {
    position: absolute;
    top: -45vh;
    right: 14px;
    border: none;
    padding: 0;
    background-color: transparent;
    display: flex;
}
.icon-style{
    text-align: center;
    display: inline-block;
}
.cursor{
    cursor: pointer;
}
.cursor:hover{
    background-color: #c6c6c6;
}
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
.material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    //font-size: 22px;
    width: 20px;
    padding: 0;
    margin: 0;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
.iconshare{
    padding-left: -5px;
    margin-top: 5px;
    font-size: 22px;
}